.access-area-status {
	&:nth-child(odd) {
		margin: 0 4px;
	}

	&:hover {
		opacity: 1;
		cursor: pointer;
	}
}

.status-badge {
	padding: 1rem;
	margin: 0.2rem;
	text-transform: uppercase;
	border-radius: 5%;
	font-weight: bold;
	color: black;

	&.connected {
		background-color: limegreen;
	}

	&.near {
		background-color: orange;
	}

	&.not-connected {
		background-color: #6e10a1;
		color: white;
	}
}
