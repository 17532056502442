//
// Services table.
//

.service-table {
	font-size: 1rem !important;
}

.ignore-header-size {
	flex: none;
}
